"use client";
import { type FC } from "react";
import { Eye, EyeSlash } from "@phosphor-icons/react/dist/ssr";
import { usePagePath } from "@/routing/usePagePath";
import { removeLeadingSlash } from "@/routing/filter-path";

export const PreviewToggle: FC<{ isEnabled: boolean }> = ({ isEnabled }) => {
  const pagePath = usePagePath();
  if (isEnabled)
    return (
      <a
        href={`/api/draft/exit?tag=${removeLeadingSlash(pagePath)}`}
        title="Avslutt forhåndsvisning"
        className="flex h-8 w-8 items-center justify-center rounded-full bg-white/75"
      >
        <EyeSlash />
      </a>
    );
  return (
    <a
      href={`/api/draft/preview?tag=${removeLeadingSlash(pagePath)}`}
      title="Forhåndsvisning"
      className="flex h-8 w-8 items-center justify-center rounded-full"
    >
      <Eye />
    </a>
  );
};

"use client";
import { FormEvent, useState } from "react";
import { getCookie, setCookie } from "@cappelendamm/cookie";
import { PiPencilLine, PiUser } from "react-icons/pi";

export enum ComponentStyle {
  MINIMIZED,
  SHOW_CURRENT_USER,
  SHOW_INPUT,
}

export const OverrideUser = () => {
  const [componentStyle, setComponentStyle] = useState<ComponentStyle>(
    ComponentStyle.MINIMIZED,
  );
  const currentOverride = getCookie("overrideUser");

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const phoneInput: string = (event.target as any)[0].value;
    setCookie({
      name: "overrideUser",
      value: phoneInput,
    });
    window.location.reload();
  }

  return (
    <>
      {componentStyle === ComponentStyle.MINIMIZED && (
        <button
          className="flex flex-row items-center gap-2"
          onClick={() =>
            setComponentStyle(
              currentOverride
                ? ComponentStyle.SHOW_CURRENT_USER
                : ComponentStyle.SHOW_INPUT,
            )
          }
        >
          <PiUser className="rounded-full bg-white/75 p-1" size={32} /> Overta
          bruker
        </button>
      )}
      {componentStyle === ComponentStyle.SHOW_CURRENT_USER && (
        <div className="flex flex-row items-center gap-2 border-l pl-2">
          <div>Nåværende bruker: {currentOverride} </div>
          <button onClick={() => setComponentStyle(ComponentStyle.SHOW_INPUT)}>
            <PiPencilLine className="rounded-full bg-white p-1" size={32} />
          </button>
        </div>
      )}
      {componentStyle === ComponentStyle.SHOW_INPUT && (
        <div className="border-l border-black pl-2">
          <form onSubmit={onSubmit} className="flex gap-2">
            <input id="phoneInput" className="w-36 p-1 pl-3" type="text" />
            <button type="submit" className="rounded-full bg-white px-4 py-1">
              Logg inn
            </button>
          </form>
        </div>
      )}
    </>
  );
};

import { getCookie, setCookie } from "@cappelendamm/cookie";
import { v4 as uuid } from "uuid";
import { getAppConfig, isBrowser } from "./global";
import logger from "./logger";
export const SESSION_FIELDS = {
    // If the user has logged in
    IDENTIFIED_ID: "cd_id",
    // The client the user is on. Used as ID when user haven't identified themselves
    CLIENT_ID: "cd_cid",
    // The session id currently active
    SESSION_ID: "cd_sid",
    // Permissions
    PERMISSIONS: "cd_perm",
};
const createId = () => {
    if (!isBrowser())
        return "";
    return uuid();
};
export const getSession = () => {
    const session = {
        userId: getCookie(SESSION_FIELDS.IDENTIFIED_ID) || null,
        clientId: getCookie(SESSION_FIELDS.CLIENT_ID) || createId(),
        sessionId: getCookie(SESSION_FIELDS.SESSION_ID) || createId(),
        permissions: JSON.parse(getCookie(SESSION_FIELDS.PERMISSIONS) || "[]"),
    };
    return session;
};
export const setSession = ({ userId, clientId, sessionId, permissions, }) => {
    if (userId)
        setCookie({ name: SESSION_FIELDS.IDENTIFIED_ID, value: `${userId}` });
    setCookie({ name: SESSION_FIELDS.CLIENT_ID, value: clientId });
    setCookie({
        name: SESSION_FIELDS.SESSION_ID,
        value: sessionId,
        expiryMinutes: getAppConfig()?.sessionLengthMinutes ?? 30,
    });
    setCookie({
        name: SESSION_FIELDS.PERMISSIONS,
        value: JSON.stringify(permissions),
    });
    return getSession();
};
export const identify = (userId) => {
    logger.log("Identifying user with known id:", userId);
    const session = getSession();
    return setSession({ ...session, userId });
};
export const setPermissions = (permissions) => {
    logger.log("Setting user permissions");
    const session = getSession();
    return setSession({ ...session, permissions });
};
export const clearPermissions = () => {
    logger.log("Clearing user permissions");
    const session = getSession();
    return setSession({ ...session, permissions: [] });
};
export const refreshSession = () => {
    logger.log("Refreshing user session");
    const session = getSession();
    return setSession(session);
};
export const clearSession = () => {
    logger.log("Clearing user session");
    return setSession({
        userId: null,
        clientId: createId(),
        sessionId: createId(),
        permissions: [],
    });
};

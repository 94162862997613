import { getAppConfig } from "./global";
import logger from "./logger";
import { refreshSession } from "./session";
import { isBrowser } from "./global";
export const getLocation = () => {
    if (!isBrowser())
        return {};
    const { origin, pathname, hash } = new URL(window.location.toString());
    return { origin, pathname, hash };
};
const getReferrer = () => {
    if (!isBrowser())
        return {};
    return { referrer: document.referrer };
};
export const getMeta = () => {
    const appConfig = getAppConfig();
    const appMeta = appConfig?.attachMeta ? appConfig.attachMeta() : {};
    return {
        ...getLocation(),
        ...getReferrer(),
        ...appMeta,
    };
};
export const trackEvent = async ({ eventName: event, ...data }) => {
    try {
        const appConfig = getAppConfig();
        const session = refreshSession();
        const payload = { ...session, ...getMeta(), event, ...data };
        await appConfig?.trackEvent(payload, { logger });
    }
    catch (e) {
        logger.error("Event tracking failed!", e);
    }
};

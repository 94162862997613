"use client";
import { ArrowLeft } from "@phosphor-icons/react/dist/ssr";
import { COOKIE_UNIVERSE } from "@/routing/routing";
import { deleteCookie } from "@cappelendamm/cookie";
import { useRouter } from "next/navigation";

export const BackToUniverseSelection = () => {
  const { refresh } = useRouter();

  const onClick = () => {
    deleteCookie(COOKIE_UNIVERSE);
    refresh();
  };
  return (
    <button className="flex h-8 w-8 items-center justify-center rounded-full bg-white/75">
      <ArrowLeft onClick={onClick} />
    </button>
  );
};

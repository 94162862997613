import { flattenRecord, unFlattenRecord } from "../utils/object";
import { TRACKING_SELECTORS } from "./constants";
import _kebabCase from "lodash/kebabCase";
import _camelCase from "lodash/camelCase";

export const isHTMLElement = (element: Element): element is HTMLElement =>
  element instanceof HTMLElement;

const isBoolean = (value?: string): boolean =>
  !!value && ["false", "true"].includes(value);

const isNumber = (value?: string): boolean =>
  !!value && !Number.isNaN(Number(value));

const isNull = (value?: string): boolean =>
  !value || ["null", "undefined"].includes(value);

const getDatasetValue = (value?: string) => {
  if (isNumber(value)) return Number(value);
  if (isBoolean(value)) return value === "true";
  if (isNull(value)) return null;
  return value;
};

export const getTrackingData = (element: HTMLElement) => {
  const attributeData = TRACKING_SELECTORS.filter(
    (attribute) => !attribute.includes("data-"),
  ).reduce((acc, attribute) => {
    if (element.hasAttribute(attribute))
      return { ...acc, [attribute]: element.getAttribute(attribute) };
    return acc;
  }, {});

  const trackedProperties = Object.entries(element.dataset)
    .filter(([key]) => key.includes("track"))
    .reduce((acc, [key, value]) => {
      const _key = key
        .replace(/track-?/, "")
        .split(".")
        .map((k) => _camelCase(k))
        .join(".");
      if (key === "track") return acc;
      return {
        ...acc,
        [_key]: getDatasetValue(value),
      };
    }, {});

  return { ...attributeData, ...unFlattenRecord(trackedProperties) };
};

export const getTrackingDataset = <
  G extends Record<string, any> = Record<string, any>,
>(
  data: G,
): Record<string, string> => {
  const flat = flattenRecord(data);
  return Object.entries(flat).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [`data-track-${key
          .split(".")
          .map((_key) => _kebabCase(_key))
          .join(".")}`]: `${value}`,
      };
    },
    { "data-track": "true" },
  );
};

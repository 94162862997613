import { getAppConfig } from "./global";
const log = (...args) => {
    if (getAppConfig()?.debug)
        console.log(...args);
};
const error = (...args) => {
    if (getAppConfig()?.debug)
        console.error(...args);
};
const logger = {
    log,
    error,
};
export default logger;

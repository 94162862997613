import Logger from "../index";
export const postSlackMessage = async (slackWebhookUrl, message) => {
    if (!slackWebhookUrl || slackWebhookUrl === "") {
        Logger.warn("postSlackMessage(): No slackWebhookUrl provided");
        return;
    }
    if (!message || message === "") {
        Logger.warn("postSlackMessage(): No message provided");
        return;
    }
    return fetch(slackWebhookUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            message,
        }),
    }).catch((e) => {
        Logger.error(e);
    });
};

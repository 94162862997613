import _set from "lodash/set";

export type FlatRecord = Record<string, string | number | boolean | null>;

export const flattenRecord = (
  record: Object,
  parentKey?: string,
): FlatRecord => {
  if (!record && parentKey) return { [parentKey]: record ?? null };
  const result: FlatRecord = Object.entries(record).reduce<FlatRecord>(
    (acc, [key, value]) => {
      const _key = parentKey ? parentKey + "." + key : key;
      if (typeof value === "object") {
        return { ...acc, ...flattenRecord(value, _key) };
      } else {
        return { ...acc, [_key]: value ?? null };
      }
    },
    {},
  );

  return result;
};

export const unFlattenRecord = (record: FlatRecord): Record<string, any> => {
  return Object.entries(record).reduce(
    (acc, [key, value]) => _set(acc, key, value ?? null),
    {},
  );
};

"use client";

import React, { FC, useEffect } from "react";
import { TRACKING_SELECTORS } from "./constants";
import tracker from "./tracker";
import { getTrackingData, isHTMLElement } from "./utils";
import { usePathname } from "next/navigation";

const isHtmlElement = (target: EventTarget | null): target is HTMLElement =>
  target instanceof HTMLElement;

export const getTrackableHtmlElement = (element: EventTarget | null) => {
  if (!isHtmlElement(element)) return;
  for (const selector of TRACKING_SELECTORS) {
    const trackableElement = element.closest(`[${selector}]`);
    if (isHtmlElement(trackableElement)) return trackableElement;
  }
};

const useAutoTrackClick = () => {
  useEffect(() => {
    const listener = ({ target }: MouseEvent) => {
      const trackingElement = getTrackableHtmlElement(target);
      if (trackingElement) {
        const trackingData = getTrackingData(trackingElement);
        tracker.trackEvent({ eventName: "click", ...trackingData });
      }
    };
    window.addEventListener("click", listener);
    return () => {
      window.removeEventListener("click", listener);
    };
  }, []);
};

const useTrackPageViews = () => {
  const pathname = usePathname();
  useEffect(() => {
    if (pathname) tracker.trackEvent({ eventName: "page_view", pathname });
  }, [pathname]);
};

const useTrackIntersectionObserver = (
  selector = '[data-track-viewport="true"]',
  event = {},
) => {
  const pathname = usePathname();
  useEffect(() => {
    const observers: IntersectionObserver[] = [];
    document.querySelectorAll(selector).forEach((item) => {
      const observer = new IntersectionObserver(
        (entry) => {
          if (
            entry.some(({ isIntersecting }) => isIntersecting) &&
            isHTMLElement(item)
          )
            tracker.trackEvent({
              eventName: "in_viewport",
              pathname,
              ...getTrackingData(item),
              ...event,
            });
        },
        {
          threshold: 0.9,
        },
      );
      observer.observe(item);
      observers.push(observer);
    });
    return () => {
      observers.forEach((observer) => observer.disconnect);
    };
  }, [pathname, selector, event]);
};

const useTrackSectionsInViewport = () =>
  useTrackIntersectionObserver("section");

export const AutoTracker: FC = () => {
  useAutoTrackClick();
  useTrackPageViews();
  useTrackSectionsInViewport();
  useTrackIntersectionObserver();
  return <></>;
};

import { PathParams } from "./types";

export const filterPath = ({
  experimentGroup,
  pathname,
  universeTag,
}: PathParams) => {
  return pathname
    .split("/")
    .filter(
      (path, index) =>
        index > 2 || // Only check the matches on first three parts: '(empty)/:experimentGroup/:universeTag'
        ![experimentGroup, universeTag].includes(path) ||
        !path,
    )
    .join("/");
};

export const removeLeadingSlash = (pathname: string) => pathname.substring(1);

import { NextRequest } from "next/server";
import { PathParams } from "./types";
import { filterPath } from "./filter-path";
import Logger from "@cappelendamm/logger";

export const getUniverseFromHost = (
  host: string,
  universeTags: string[],
): string | null => {
  const matcher = new RegExp(`(?<tag>${universeTags.join("|")})`, "gi");
  const matches = host.match(matcher);
  if (!matches) return null;
  const universe = `${matches[0]}`.toLowerCase();
  Logger.log(`Resolved universe: ${universe} - Host: ${host}`);
  return universe;
};

export const COOKIE_UNIVERSE = "universe";

export const getUniverseCookie = (request: NextRequest) => {
  const universe = request.cookies.get(COOKIE_UNIVERSE)?.value;
  if (!universe) return null;
  Logger.log(
    `Resolved universe from cookie: ${universe} - Host: ${request.nextUrl.host}`,
  );
  return universe;
};

export const getAppPathname = ({
  experimentGroup,
  pathname,
  universeTag,
}: PathParams) => {
  const pagePath = filterPath({ experimentGroup, pathname, universeTag });
  if (!universeTag) return `/${experimentGroup}${pagePath}`;
  return `/${experimentGroup}/${universeTag}${pagePath}`;
};

export const getQueryParams = (searchParams: URLSearchParams) => {
  if (searchParams.toString().length > 0) {
    return `?${searchParams}`;
  }
  return "";
};
// export const isGlobalPath = (pathname: string): boolean =>
//   Object.entries(GLOBAL_PATHS).some(
//     ([name, path]) =>
//       pathname.startsWith(`/${name}`) || pathname.startsWith(path),
//   );

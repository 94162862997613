import { setAppConfig } from "./global";
import logger from "./logger";
import { refreshSession, identify, setPermissions, clearPermissions, clearSession, } from "./session";
import { trackEvent } from "./tracker";
const initializeTracker = ({ userId = null, permissions, ...appConfig }) => {
    logger.log("Initializing tracking with", appConfig);
    const config = setAppConfig(appConfig);
    logger.log("Starting session with: ", { identify, permissions });
    if (userId)
        identify(userId);
    if (permissions)
        setPermissions(permissions);
    const session = refreshSession();
    logger.log("Tracking initialized");
    return {
        session,
        config,
        clearSession,
        clearPermissions,
        identify,
        setPermissions,
        trackEvent,
    };
};
export default initializeTracker;

export const getAppConfig = () => {
    if (!isBrowser())
        return undefined;
    return window.cdTracking;
};
export const setAppConfig = (config) => {
    if (!isBrowser())
        return undefined;
    window.cdTracking = config;
    return window.cdTracking;
};
export const isBrowser = () => typeof window !== "undefined";

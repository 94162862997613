"use client";

import ReactDOM from "react-dom";

export function PreloadFonts() {
  ReactDOM.preload("https://use.typekit.net/iea7ded.css", { as: "style" });
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/iea7ded.css" />
    </>
  );
}

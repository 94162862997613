const isBrowser = () => typeof window !== "undefined";
// Borrowed from [w3schools](https://www.w3schools.com/js/js_cookies.asp)
export const setCookie = ({ name, value, expiryMinutes = 60 * 24 * 180, }) => {
    if (!isBrowser())
        return;
    const d = new Date();
    d.setTime(d.getTime() + expiryMinutes * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
};
export const getCookie = (name) => {
    if (!isBrowser())
        return "";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(`${name}=`) == 0) {
            return c.substring(name.length + 1, c.length);
        }
    }
    return "";
};
export const deleteCookie = (name) => {
    if (!isBrowser())
        return;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
